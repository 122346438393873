<template>
  <div class="container my-5 pt-2">
    <div v-if="!query" class="text-center my-5 py-5">
      <h3>Please enter a search term.</h3>
    </div>
    <div v-else>
      <sp-loader v-if="isLoading" />
      <div v-else>
        <div class="row">
          <div class="col-12 d-block d-lg-none">
            <b-button variant="outline-primary" class="float-right mb-3" @click="showFiltersForSmall = !showFiltersForSmall">
              Refine Search
            </b-button>
          </div>
          <div id="filterBlock" class="col-12 col-lg-3 p-0" :class="{'d-none': !showFiltersForSmall, 'd-lg-block': !showFiltersForSmall}">
            <div v-if="referenceObj">
              <!-- The masterKey from the top loop will be used as a pointer to the data in the second. The top loop is of a fixed size -->
              <!-- This cannot be bound to 'key' because the inside loop is -->
              <div v-for="(value, masterKey, index) in referenceObj" :key="index" class="container-fluid mb-5">
                <div v-if="(options[masterKey] && options[masterKey]['length']) || isFilterSearch" class="row my-2 font-weight-bold">
                  {{ options[masterKey]["displayText"] }}
                </div>
                <div v-for="(value2, key, index2) in referenceObj[masterKey]" :key="index2">
                  <!-- Limit the number of filters shown by checking the loop index number and stay under the constraint -->
                  <div v-if="index2 < options[masterKey]['constraintLengthInTemplate']" class="row pl-1 check-input pointer"
                       @click="toggleFilter(key, masterKey)">
                    <div class="col-10 text-truncate pl-1 mb-1 ">
                      <font-awesome-icon class="mr-1 checkbox" size="lg" :icon="referenceObj[masterKey][key].selected ? ['fas', 'check-square'] : ['fas', 'square']" />
                      <span v-if="options[masterKey]['isCustomOrdered']">
                        {{ value2["count"][0] }}
                      </span>
                      <span v-else>
                        {{ key }}
                      </span>
                    </div>
                    <div class="col-2 p-0 m-0 text-right">
                      <small v-if="options[masterKey]['isCustomOrdered']" class="text-faded">({{ value2["count"][1] }})</small>
                      <small v-else class="text-faded">({{ value2["count"] }})</small>
                    </div>
                  </div>
                </div>
                <!-- If the length is greater than the minimum, AND the field is being constrained "show more" -->
                <div v-if="options[masterKey]['length'] > 5 && options[masterKey]['constraintLengthInTemplate'] !== 100" class="row pl-2">
                  <span class="text-primary-green pointer" @click="toggleMore(masterKey)">
                    Show More
                  </span>
                </div>
                <!-- If the field is not being constrained, "show less" -->
                <div v-if="options[masterKey]['constraintLengthInTemplate'] === 100" class="row pl-2">
                  <span class="text-primary-green pointer" @click="toggleMore(masterKey)">
                    Show Less
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Search Results -->
          <div v-if="searchTime && query" class="col col-12 col-lg-9">
            <ul class="pl-0 pl-md-4 pt-3 list-group-flush">
              <p class="lead">
                Your search for <strong>{{ query }}</strong> produced
                <strong>{{ totalMatches }}</strong> results in {{ searchTime }} seconds.
              </p>
              <!-- Show course results above lesson results jhamm 2/5/19 -->
              <h2 class="mt-4 mb-3">
                Courses
              </h2>
              <div v-if="courseResults && courseResults.length > 0">
                <b-link
                  v-for="course in courseResults"
                  :key="course.id"
                  :href="SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + course.url"
                  class="list-group-item list-group-item-action">
                  <h5 class="mb-1 font-weight-semibold">
                    {{ course.name }}
                  </h5>
                  <small>
                    <span class="text-muted mr-2">Found in:</span>
                    <span class="mr-2">
                      <b-link :href="SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + course.software_url" class="text-muted">{{ course.software }}</b-link>
                    </span>
                  </small>
                </b-link>
              </div>
              <div v-else class="ml-4">
                <p class="text-muted">
                  No courses found
                </p>
              </div>
              <h2 class="mt-4 mb-3">
                Lessons
              </h2>
              <div v-if="results">
                <b-link
                  v-for="result in results"
                  :key="result.docid"
                  :href="result.lesson_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.lesson_url : result.url"
                  class="list-group-item list-group-item-action"
                  @click="recordEvent(result.lesson_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.lesson_url : result.url)">
                  <h5 class="mb-1 font-weight-semibold">
                    {{ result.title | striphtml }}
                  </h5>
                  <p class="mb-1">
                    {{ result.description | striphtml }}
                  </p>
                  <small>
                    <span class="text-muted mr-2">Found in:</span>
                    <span class="mr-2">
                      <b-link :href="result.software_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.software_url : result.url" class="text-muted" @click="recordEvent( result.software_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.software_url : result.url)">{{ result.software }}</b-link></span><span class="mr-2">/</span>
                    <span class="mr-2">
                      <b-link :href="result.course_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.course_url : result.url" class="text-muted" @click="recordEvent(result.course_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.course_url : result.url)">{{ result.course }}</b-link></span><span class="mr-2">/</span>
                    <span class="">
                      <b-link :href="result.lesson_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.lesson_url : result.url" class="text-muted" @click="recordEvent(result.course_url ? SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.course_url : result.url)">{{ result.section }}</b-link></span>
                  </small>
                </b-link>
              </div>
            </ul>
            <InfiniteLoading
              v-if="results && results.length > 0"
              ref="infiniteLoading"
              spinner="bubbles"
              @infinite="loadMore">
              <span slot="no-results" />
              <span slot="no-more">All results loaded</span>
              <span slot="spinner" class="text-muted"><font-awesome-icon icon="spinner" pulse size="2x" /></span>
            </InfiniteLoading>
          </div>
        </div>
      </div>
      <div v-if="error" class="text-center my-5 py-5">
        <h3>Sorry, something went wrong.</h3>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Important! This vue builds filters dynamically. To import new filters, they will need an entry in 'options', and 'referenceObj'
 * If you are doing custom ordering of a result subset, you will need to build an individual sorting function, call it in 'doSearch', and add the option to 'options'
 */
import BaseComponent from '@/components/BaseComponent';
import SearchUtility from '@/utils/SearchUtilities.js';
import Vue from 'vue';
import jsonp from 'jsonp';
import axios from 'axios/index';
import _ from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Results',
  components: {
    InfiniteLoading
  },
  extends: BaseComponent,
  data() {
    return {
      searchUrl:
        'https://dyb8n.api.searchify.com/v1/indexes/' +
        process.env.SPENV.SEARCHIFY_INDEX +
        '/search?fetch=name,docid,title,description,thumbnail,url,lesson_url,course,course_url,section_id,section,lesson_script,course_id,software_id,software,software_url,content_type"',
      courseIDs: null,
      results: null,
      courseResults: null,
      totalMatches: null,
      searchTime: null,
      error: false,
      // The following three variables are used to instantiate a computed value. They should not be referenced directly
      facetYears: {},
      contentType: {},
      softwareType: {},
      // Useful variables to be retrieved by reference. Add value here to automagically add to template and logic
      options: {
        Software: {
          queryString: 'software:(',
          displayText: 'Software',
          constraintLengthInTemplate: 5,
          length: 0,
          isCustomOrdered: false
        },
        Course_Year: {
          queryString: 'course_year:(',
          displayText: 'Year',
          constraintLengthInTemplate: 5,
          length: 0,
          isCustomOrdered: true
        },
        Content_Type: {
          queryString: 'content_type:(',
          displayText: 'Type',
          constraintLengthInTemplate: 5,
          length: 0,
          isCustomOrdered: false
        }
      },
      softwareList: {},
      showFiltersForSmall: false,
      // If true will persist the filter column in the ux (indicates a filter initiated search)
      isFilterSearch: false
    };
  },
  metaInfo() {
    return {
      links: [
        {
          rel: 'canonical',
          href: this.SPENV.WWW_URL + '/search?q=' + this.query
        }
      ],
      meta: [
        {
          name: 'viewport', content: 'width=device-width, initial-scale=1'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'SolidProfessor Search Results for ' + this.query
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'training, tutorial, certification, courses, video, learn, online, how to, ' + this.query
        },
        // OpenGraph data (Used by Facebook to generate in-page tiles)
        {
          property: 'og:title',
          content: 'SolidProfessor Search'
        },
        {
          property: 'og:site_name',
          content: 'SolidProfessor.com'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:url',
          content: this.SPENV.WWW_URL + '/search?q=' + this.query },
        {
          property: 'og:image',
          content: this.SPENV.S3_URL + '/images/logos/sp-logo.png' },
        {
          property: 'og:description',
          content: 'SolidProfessor Search Results for ' + this.query },

        // Twitter card
        {
          name: 'twitter:card',
          content: 'summary' },
        {
          name: 'twitter:site',
          content: 'www.SolidProfessor.com' },
        {
          name: 'twitter:title',
          content: 'SolidProfessor Search' },
        {
          name: 'twitter:description',
          content: 'SolidProfessor Search Results for ' + this.query
        },
        {
          name: 'twitter:creator',
          content: '@solidprofessor'
        },
        {
          name: 'twitter:image:src',
          content: this.SPENV.S3_URL + '/images/logos/sp-logo.png'
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content: 'SolidProfessor Search'
        },
        {
          itemprop: 'description',
          content: 'SolidProfessor Search results for ' + this.query
        },
        {
          itemprop: 'image',
          content: this.SPENV.S3_URL + '/images/logos/sp-logo.png'
        }
      ]
    };
  },
  computed: {
    query() {
      return this.$route.query.q;
    },
    // referenceObj is used to dynamically instantiate filters. Keys added here will automatically be added to the template and logic
    referenceObj() {
      return {
        Software: this.softwareType,
        Course_Year: this.facetYears,
        Content_Type: this.contentType
      };
    }
  },
  watch: {
    // if query changes, run a new search
    query() {
      this.newSearch();
    }
  },
  created() {
    let loadUser = this.$store.dispatch('user/loadUserInfo');
    let loadSearchWeights = this.$store.dispatch('search/findSearchWeights');

    Promise.all([loadUser, loadSearchWeights]).then(() => {
      this.newSearch();
    });
  },
  methods: {
    /**
     *@author srogalsky
     * 5/4/18
     * Query searchify with a new search term
     * Set component vars with the results
     */
    newSearch() {

      this.isLoading = true;

      this.isFilterSearch = false;
      if (!this.query) {
        return;
      }

      // On new searches, any existing tags need removed
      let facetKeys = Object.keys(this.referenceObj);
      facetKeys.forEach(facetKey => {
        Vue.set(this.referenceObj, facetKey, {});
        this.options[facetKey]['length'] = 0;
        this.options[facetKey]['constraintLengthInTemplate'] = 5;
      });

      // If the user is a HawkRidge user, start with Solidworks selected
      if (this.user && this.user.is_hawkridge) {
        Vue.set(this.referenceObj, 'Software', {'SOLIDWORKS': {selected: true}});
      }

      this.results = [];
      this.courseResults = [];
      axios.get(this.SPENV.APP_URL + '/api/v2/course/ids', {withCredentials: true}).then(
        (response) => {
          this.courseIDs = response.data;
          this.doSearch().then(
            (data) => {
              this.totalMatches = data.matches;
              this.searchTime = data.search_time;
              this.isLoading = false;
            });
        })
        .catch(
          (error) => {
            console.log(error);
            this.error = true;
            this.loading = false;
          });
    },

    // Big ugly function to specifically process and order facet years
    processYears(data) {
      let thisYear = new Date().getFullYear();
      let returnData = {};
      let matchNumber = 0;

      // Check for next year's software, because that's a thing
      if (data[thisYear + 1]) {
        let newObj = [];
        newObj.push(thisYear + 1);
        newObj.push(data[thisYear + 1]);
        returnData[matchNumber] = newObj;
        data[thisYear + 1] = null;
        matchNumber++;
      }

      // Check for all the other years
      while (thisYear > 2000) {
        if (data[thisYear]) {
          let newYearObj = [];
          newYearObj.push(thisYear);
          newYearObj.push(data[thisYear]);
          returnData[matchNumber] = newYearObj;
          data[thisYear] = null;
          matchNumber++;
        }
        thisYear--;
      }

      // Check for things that aren't years
      Object.keys(data).forEach(key => {
        if (data[key]) {
          let newObj = [];
          newObj.push(key);
          newObj.push(data[key]);
          returnData[matchNumber] = newObj;
          matchNumber++;
        }
      });

      return returnData;
    },
    /**
     * Flip the boolean when a filter is selected and update search
     */
    toggleFilter(key, type) {
      let selected = false;
      if (this.referenceObj[type][key]) {
        selected = !this.referenceObj[type][key]['selected'];
      }
      Vue.set(this.referenceObj[type][key], 'selected', selected);
      this.isFilterSearch = true;
      this.doSearch(true);
    },
    /**
     * The 'show more' and 'show less' arbitrarily constrain the number of filters displayed in the template
     * Currently, we use '100' as 'non-constrained' and build our logic off of that.
     */
    toggleMore(masterKey) {
      if (this.options[masterKey]['constraintLengthInTemplate'] === 100) {
        this.options[masterKey]['constraintLengthInTemplate'] = 5;
      } else {
        this.options[masterKey]['constraintLengthInTemplate'] = 100;
      }
    },
    /**
     * Process incoming facets returned from intitial search
     * The facets will be used to dynamically generate the filters section of the template, and corresponding logic
     */
    processFacets(data) {
      let facetKeys = Object.keys(this.referenceObj);
      facetKeys.forEach(facetKey => {
        if (data[facetKey]) {
          this.processContentType(data[facetKey], facetKey);
        } else {
          // Remove any existing data
          Vue.set(this.referenceObj, facetKey, {});
          this.options[facetKey]['length'] = 0;
        }
      });
    },
    /**
     * For each filter type, build the associated object.
     * referenceObj is used so that we may later dynamically build filters by reference
     */
    processContentType(data, facetKey) {
      let tempObj = {};
      let keys = Object.keys(data);

      // For each of the main filter sections
      keys.forEach(key => {
        // Do special logic for course years
        if (facetKey === 'Course_Year') {
          // 'keyFound' is a special designator. If a single year is selected, we want to exclude all other years and return only a single object
          // but if we get to the end of the list and none of the years have been selected, we want to return ALL the years
          let keyFound = false;
          if (this.referenceObj.hasOwnProperty('Course_Year') && Object.keys(this.referenceObj['Course_Year']).length) {
            // Go through each available year and check if it has been selected
            Object.keys(this.referenceObj['Course_Year']).forEach(searchKey => {
              if (this.referenceObj['Course_Year'][searchKey]['selected']) {
                tempObj[key] = { selected: true, count: data[key] };
                keyFound = true;
              }
            });
          }
          // If an item was never found to be 'true', return them all with nothing selected
          if (!keyFound) {
            tempObj[key] = { selected: false, count: data[key] };
          }
          // Logic for all other filters
        } else {
          if (this.referenceObj && this.referenceObj[facetKey] && this.referenceObj[facetKey][key]) {
            // Look for checked objects and keep them selected
            if (this.referenceObj[facetKey] && this.referenceObj[facetKey][key] && this.referenceObj[facetKey][key]['selected']) {
              tempObj[key] = { selected: true, count: data[key] };
            } else {
              // else just make them all false
              tempObj[key] = { selected: false, count: data[key] };
            }
          } else {
            // On new searches, all results will fall through here
            tempObj[key] = { selected: false, count: data[key] };
          }
        }
      });

      // Set the length of the corresponding 'facetString' object on the facetKeys
      this.options[facetKey]['length'] = keys.length;
      Vue.set(this.referenceObj, facetKey, tempObj);
    },
    /**
     * Modify the query based on the facets
     * If any of the referenceObj' 'selected' boolean is truthy, the facet will be used to constrain the search
     * Unfortunately, Searchify does not have a js library, so we build this ugly string instead.
     * This will dynamically scale for all facets we may choose to add in the future, provided we allow for their type in 'options'
     *
     * @return String -- a concat of the original queryString and the new search facets
     */
    queryFacetBuilder() {
      let facetKeys = Object.keys(this.referenceObj);
      let stringBase = ' AND ';
      let queryString = '';

      // forEach category type
      facetKeys.forEach(facet => {
        if (Object.keys(this.referenceObj[facet]).length) {
          let facetSubKeys = Object.keys(this.referenceObj[facet]);
          let pristine = true;
          // Does the actual query string building, appending each positive facet
          // forEach property 'key' on the category -- e.g. the '2008' in self.referenceObj['Course_Years']['2008']['selected'] = ?
          facetSubKeys.forEach(key => {
            if (this.referenceObj[facet][key]['selected'] === true) {
              if (pristine === true) {
                pristine = false;
                // If the object is custom ordered, the data will be slighly deeper
                if (this.options[facet]['isCustomOrdered']) {
                  queryString = queryString + stringBase + this.options[facet]['queryString'] + '"' + String(this.referenceObj[facet][key]['count'][0]) + '"';
                } else {
                  queryString = queryString + stringBase + this.options[facet]['queryString'] + '"' + String(key) + '"';
                }
              } else {
                // If the object is custom ordered, the data will be slighly deeper
                if (this.options[facet]['isCustomOrdered']) {
                  stringBase = stringBase + ' OR ' + '"' + String(this.referenceObj[facet][key]['count'][0]) + '"';
                } else {
                  stringBase = stringBase + ' OR ' + '"' + String(key) + '"';
                }
              }
            }
          });
          // If facets have been added, append the closing bracket at the end of the loop
          if (!pristine) {
            queryString = queryString + ')';
          }
        }
      });
      // Console log here to verify queryString. New constraining parameters are added to the end
      // DEBUG: console.log('Formatted queryString', queryString)
      return queryString;
    },
    /**
     * @author srogalsky
     * 5/4/18
     * Build searchify query and send
     * @return Promise containing search results
     */
    doSearch(redoSearch = false) {
      // save search term when getting to this page
      SearchUtility.saveSearchKeyword(this.query);
      let start = this.results.length;

      // If filters are changed, start over
      if (redoSearch) {
        start = 0;
        this.results = [];
        this.courseResults = [];
      }

      let url = this.searchUrl + '&start=' + start + '&len=20&q=' + SearchUtility.buildQuery(this.query, this.courseIDs, this.$store.getters['search/getSearchWeights'], this.queryFacetBuilder());
      let opts = {};
      return new Promise((resolve, reject) => {
        jsonp(url, opts, (err, data) => {
          if (err) {
            reject(err);
          }
          // add the next chunk to results. If the filters are changed, return a fresh list, otherwise concat search for never-ending scroll
          if (data.results){
            if (redoSearch) {
              this.results = data.results;
              this.courseResults = this.filterCourseResults(data.results);
            } else {
              this.results = this.results.concat(data.results);
              this.courseResults = this.filterCourseResults(data.results);
            }
          }

          // Custom ordering for course years. If you need to custom order something else, do it like so.
          if (data.facets.Course_Year) {
            data.facets.Course_Year = this.processYears(
              data.facets.Course_Year
            );
          }
          this.totalMatches = data.matches;
          this.searchTime = data.search_time;
          this.processFacets(data.facets);

          resolve(data);
        });
      });
    },
    /**
     * @author srogalsky
     * 5/4/18
     * infinite scroll event handler
     * Load more results from searchify
     * @param $state
     */
    loadMore($state) {
      this.doSearch().then(
        () => {
          $state.loaded();
          if (this.results.length >= this.totalMatches) {
            $state.complete();
          }
        });
    },
    filterCourseResults(results) {
      let courses = _.groupBy(results, 'course_id');
      let courseList = [];
      _.map(courses, course => {
        courseList.push({
          id: course[0].course_id,
          name: course[0].course,
          url: course[0].course_url,
          software: course[0].software,
          software_url: course[0].software_url,
          score: course[0].query_relevance_score
        });
        return courseList;
      });
      return _.orderBy(courseList, ['score'], ['desc']).slice(0, 5);
    },
    recordEvent(url) {
      this.analyticsClickEvent('Search', 'Click result', 'url: ' + url);
    }
  }
};
</script>

<style></style>
